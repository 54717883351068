import * as React from "react";
import { motion } from "framer-motion";
import Layout from "../components/layout";
import { StaticImage } from "gatsby-plugin-image";
import "../styles/index.scss";
import "../styles/about.scss";
import { Link } from "gatsby";

// markup
const AboutPage = () => {
  return (
    <Layout newStyle="dark" pageTitle="about">
      <header className="header">
        <div className="container">
          <motion.h1
            initial={{ opacity: 0, y: "20vh" }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.5, duration: 1 }}
            className="header__title"
          >
            Senior Product Designer with over 5 years
            experience passionate about designing simple, usable and impactful
            products.
          </motion.h1>
        </div>
      </header>
      <motion.section
        initial={{ opacity: 0, y: "20vh" }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5, duration: 1 }}
        className="about__wrapper"
      >
        <div className="container">
          <div className="about__content">
            <div className="about__content__text">
              <p>
                I help early-stage and mid-sized companies create innovative
                products that solves problems, and are impactful and creates
                delightful experiences for users.  My career started off in frontend development, and picked up
                design along the way. I am
                passionate about helping the world be a better place by
                user-centric digital experiences that are seamless to use and
                makes living a lot less stressful.
                When I am not working, you can find me watching an action movie,
                sleeping, or hanging out with my friends. Over the last five years of professional
                experience, I have had the opportunity to lead design across various industries helping drive innovation at scale for companies like AKQA, IBM, BMW Performance Centre, Runbuggy, Urbansitter, Indicina, Bumpa.  I also recently completed a Master’s in Human-Computer
                Interaction Design at the <Link>University of Leicester</Link>, graduating with a distinction. In my spare time, I mentor
                early-career designers through{" "}
                <a
                  href="https://designlab.com/adeadebimpe/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Designlab
                </a>
                , providing them guidance and advice as they navigate their UX
                design career.
         
              </p>

              <a
                href="https://drive.google.com/file/d/1GALce-_IRlaxtn7st72XxjDS1mU2zOz8/view?usp=sharing"
                target="_blank"
                rel="noreferrer noopener"
                className="about__content__link"
              >
                Check out my resume
              </a>
            </div>
          </div>
        </div>
      </motion.section>
    </Layout>
  );
};

export default AboutPage;
